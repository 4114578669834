import ShortsView from "../components/ShortsVerticalScroll";
import {useLocation} from "react-router";
import React, {useEffect, useState} from "react";
import axios from "axios";
import UrlConstant from "../constants/UrlConstant";
import Header from "../components/Header";

const ShortsPage = () => {
    const [shorts, setShorts] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const videoId = queryParams.get('video');
        const code = queryParams.get('code');

        // Make Axios call to fetch shorts info based on videoId and code
        axios.get(`${UrlConstant.HOST}/video?video_id=${videoId}&code=${code}`)
            .then(response => {
                // setShorts(response.data);
                setShorts(response.data)
            })

            .catch(error => {
                console.error('Error fetching shorts:', error);
                if(error.response.status === 400) {
                    setErrorMessage(error.response.data.detail)
                }
            });

    }, [location.search]);


    return (
        <div className="min-h-screen bg-gradient-to-br from-purple-300 to-gray-100"  style={{background: "#022C3A", fontFamily: "Outfit, sans-serif"}}>
            <Header />

            {errorMessage && <p>{errorMessage}</p>}
            <ShortsView shorts={shorts}/>
        </div>
    );
};

export default ShortsPage;