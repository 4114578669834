import React, { useState } from 'react';
import { useNavigate } from "react-router";
import axios from 'axios';

import Header from "../components/Header";
import "./loginpage.css"
import UrlConstant from "../constants/UrlConstant";

function LoginPage() {
    const [email, setEmail] = useState('');
    const [invitationCode, setInvitationCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        setErrorMessage('')
        setEmail(e.target.value);
    }
    const handleInvitationCodeChange = (e) => {
        setErrorMessage('')
        setInvitationCode(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${UrlConstant.HOST}/login`, {
                email: email,
                invitation_code: invitationCode,
            });

            console.log(response)

            localStorage.setItem("email", response.data.email)
            localStorage.setItem("invitationCode", response.data.invitation_code)
            localStorage.setItem("fullname", response.data.fullname)
            localStorage.setItem("invitedDate", response.data.invited_date)
            localStorage.setItem('subscriptionValidForDays', response.data.subscription_valid_for_days)
            localStorage.setItem('brandColor', response.data.brand_color)

            navigate('/');
        } catch (error) {
            console.log(error)
            if (error.response && error.response.status === 401) {
                setErrorMessage(error.response.data.detail || "Unauthorized access - invalid invitation code");
            } else {
                setErrorMessage("An error occurred. Please try again later.");
            }
        }
    };

    return (
        <>
            <Header />
            <div
                 className="w-full lg:w-1/2 mx-auto my-10 lg:my-0 px-5 lg:px-20 py-10 lg:py-40 pl-20 lg:pl-100 rounded-lg"
                 style={{ outline: "2px solid #033C4A", margin: "2.5rem auto", padding: "40px 20px 40px 100px", borderRadius: "10px" }}

            >
                <div className="mb-12 text-xl">
                    <p>Got invitation code? Use Compass AI from here!</p>
                </div>
                {
                    errorMessage && <div className="mb-4 text-red-500">{errorMessage}

                </div>
                }
                <form onSubmit={handleSubmit}>
                    <div>
                        <label className="inline-block mb-1">Email</label> <br />
                        <input type="text" className="input-style md:w-[400px]" placeholder="Enter your email" value={email} onChange={handleEmailChange} />
                    </div>
                    <div className="mt-8">
                        <label className="inline-block mb-1">Invitation code</label> <br />
                        <input type="text" className="input-style md:w-[400px]" placeholder="Enter your invitation code" value={invitationCode} onChange={handleInvitationCodeChange} />
                    </div>
                    <button type="submit" className="solid-button mt-10">Use Compass AI</button>
                </form>
            </div>
        </>
    );
}

export default LoginPage;
