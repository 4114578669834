import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { FaDownload } from 'react-icons/fa'; // Importing download icon
import { FiLoader } from 'react-icons/fi'; // Importing loading icon
import Header from "./Header";
import UrlConstant from "../constants/UrlConstant";

const ShortsVerticalScroll = ({shorts}) => {
    const [loading, setLoading] = useState(false);

    const downloadVideo = (url, filename) => {
        setLoading(true);
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                setLoading(false);
            })
            .catch((e) => {
                alert('Failed to download video. Please try again.');
                console.log(e)
                setLoading(false);
            });
    };

    return (
        <div className="container mx-auto px-4 py-16 md:py-16">
            {shorts.map((video, index) => (
                <div className="mx-auto" key={index} style={{width: '60%'}}>
                    <h2 className="text-xl font-normal text-gray-200 mb-2">{video.title}</h2>
                    <p className="text-gray-600 text-sm mb-2">{video.duration}</p>

                    <div className="mb-16 md:flex md:items-center">
                        {/* Download Icon */}
                    <div className="">
                        <button
                            onClick={() => downloadVideo(`${UrlConstant.HOST}/video_file?file_path=${video.mp4_url}`, `${video.title}.mp4`)}
                            className="text-gray-400 hover:text-gray-200 focus:outline-none mr-5"
                            disabled={loading}
                            title="Download"
                        >
                            {loading ? <FiLoader className="animate-spin" size={24} /> : <FaDownload size={24} />}
                        </button>
                    </div>

                        {/* Video Player */}
                        <div
                            className="rounded-lg overflow-hidden shadow-md mb-4 md:mb-0 md:mr-4 md:w-1/2 md:flex-shrink-0"
                            style={{width: '30%'}}
                        >
                            <ReactPlayer
                                url={`${UrlConstant.HOST}/video_file?file_path=${video.mp4_url}`}
                                controls
                                width="100%"
                                height="auto"
                                className="rounded-lg overflow-hidden"
                            />
                        </div>

                        {/* Transcript */}
                        <div
                            className="p-4 bg-gray-100 rounded-lg shadow-md md:w-1/2 text-center border-2  md:ml-4"
                            style={{marginTop: 'auto', marginBottom: 'auto', width: '100%'}}
                        >
                            <p className="text-gray-800 text-lg leading-relaxed">{video.subtitle_content}</p>
                        </div>
                    </div>


                </div>
            ))}
        </div>
    );
};

export default ShortsVerticalScroll;
