import './App.css';
import {Route, Routes} from "react-router";
import WaitingPage from "./components/WaitingPage";
import { BrowserRouter } from "react-router-dom";
import ShortsPage from "./pages/ShortsPage";
// import HomePage from "./components/HomePage";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";


function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route index element={<HomePage />} />
            <Route path="/waiting" element={<WaitingPage />} />
            <Route path="/shorts" element={<ShortsPage />} />
            <Route path="/login" element={<LoginPage />} />
        </Routes>

    </BrowserRouter>
  );
}

export default App;
