import React from 'react';
import { Link } from "react-router-dom";
import { FaSignOutAlt } from 'react-icons/fa';

const Header = () => {
    const style = {
        fontWeight: 600,
        fontSize: "32px",
        color: "white"
    }

    const inviteOnlyBetaStyle = {
        fontSize: "0.7rem",
        position: "relative",
        fontWeight: "600",
        padding: "2px"
    }

    const inviteOnlyStyle = {
        background: "white",
        color: "#022C3A",
        padding: "3px 4px 3px 4px",
        fontWeight: "800"
    }

    const handleLogout = () => {
        localStorage.removeItem('fullname');
        localStorage.removeItem("email");
        localStorage.removeItem("invitationCode");
        localStorage.removeItem("invitedDate")
        localStorage.removeItem('subscriptionValidForDays');
        window.location.reload();
    };

    const subscriptionValidForDays = localStorage.getItem('subscriptionValidForDays');


    return (
        <>
            <div className="flex flex-wrap justify-between">
                <div className="flex sm:px-6 md:px-3 lg:px-[100px] py-[20px]" style={style}>
                    <img src="/logo.png" className="" style={{height: "64px", width: "64px"}} alt="logo"/>
                    <h1 className="mt-[10px]">Compass AI <span style={{color: "#12DA02"}}>.</span></h1>
                    <p style={inviteOnlyBetaStyle}><span style={inviteOnlyStyle}>Invite only</span></p>
                </div>

                <div className="flex items-center">
                    {localStorage.getItem('fullname') ? (
                        <div className="flex items-center sm:px-6 md:px-3 lg:px-[100px] py-[20px]">
                            <p className="mr-4 text-sm">Subscribed for <span style={{outline: "1px solid", padding: '1px', paddingLeft: '4px', paddingRight: '4px'}}>{subscriptionValidForDays} days</span></p>
                            <p className="mr-4">{localStorage.getItem('fullname')}</p>
                            <FaSignOutAlt className="cursor-pointer" title="Logout" onClick={handleLogout} />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
};

export default Header;
