import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Header from './Header';
import UrlConstant from '../constants/UrlConstant';
import {useSearchParams} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {FaSpinner} from 'react-icons/fa';

const WaitingPage = () => {
    const [email, setEmail] = useState('');
    const [showEmailInput, setShowEmailInput] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isEditingEmail, setIsEditingEmail] = useState(false);
    const [isConfirmingEmail, setIsConfirmingEmail] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isGetShortsClicked, setIsGetShortsClicked] = useState(false);
    const [serverErrorMessage, setServerErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [successStatus, setSuccessStatus] = useState(false)

    const [searchParams] = useSearchParams();
    const videoUrl = searchParams.get('video_url');

    useEffect(() => {
        const storedEmail = localStorage.getItem('userEmail');
        if (storedEmail) {
            setEmail(storedEmail);
            setShowEmailInput(false);
            setIsConfirmingEmail(true);
        }
    }, []);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setIsEmailValid(true);
    };

    const handleEmailSubmit = () => {
        if (!email || !email.includes('@')) {
            setIsEmailValid(false);
            return;
        }

        localStorage.setItem('userEmail', email);
        setShowEmailInput(false);
        setIsConfirmingEmail(true);
    };

    const handleEditEmail = () => {
        setIsEditingEmail(true);
        setShowEmailInput(true);
        setIsConfirmingEmail(false);
    };

    const extractVideoId = (url) => {
        const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        return match && match[1];
    };

    const handleGetShorts = async () => {
        setServerErrorMessage('');
        setIsLoading(true); // Set loading state to true
        const videoUUID = uuidv4().replace(/-/g, '');

        try {
            setIsProcessing(true);
            setIsGetShortsClicked(true);
            const response = await axios.post(`${UrlConstant.HOST}/video`, {
                video_url: videoUrl,
                video_id: videoUUID,
                email: email,
            });

            setSuccessStatus(true)

            console.log(response);
        } catch (err) {
            setSuccessStatus(false)
            if (err.response) {
                if (err.response.status in [400, 401, 404]) {
                    console.error('Client Error:', err.response);
                    setServerErrorMessage(
                        err.response.data.detail || 'Error occur from browser. Retry in a while.'
                    );
                } else {
                    console.error('Server Error:', err.response);
                    setServerErrorMessage(
                        err.response.data.message || 'Error occurred at server. Retry in a while.'
                    );
                }
            } else if (err.request) {
                console.error('Network Error:', err.request);
                setServerErrorMessage('No response received from the server. Please check your internet connection.');
            } else {
                console.error('Error:', err.message);
                setServerErrorMessage('Error occurred. Retry in a while.');
            }

            setIsGetShortsClicked(false);
        } finally {
            setIsLoading(false); // Reset loading state
        }
    };

    return (
        <div className="min-h-screen text-white" style={{ background: "#022C3A", fontFamily: "Outfit, sans-serif" }}>
            <Header />
            {
                serverErrorMessage &&
                <p className="py-4 md:py-4 px-6 md:px-32 text-center">{serverErrorMessage}</p>
            }

            <div className="container mx-auto px-6 md:px-32 py-12 md:py-18 flex flex-col md:flex-row">
                <div className="mb-8 md:mb-0 md:mr-8  mx-auto">
                    <img
                        src={`https://i.ytimg.com/vi/${extractVideoId(videoUrl)}/hqdefault.jpg`}
                        alt="Thumbnail Preview"
                        className="rounded-lg shadow-md"
                        style={{ maxWidth: '300px' }}
                    />
                </div>

                <div className="max-w-xl mx-auto text-center">
                    {!isProcessing && (
                        <div className=" text-gray-200 rounded-lg mb-6">
                            <p className="text-3xl mb-2">
                                <span className="font-bold">Ready to go? </span>
                            </p>
                            <p className="text-lg">
                                Once your email is set, click the button below to start processing.
                            </p>
                            <p className="mb-8">
                                We will send you shorts to this email when ready.
                            </p>
                            {isConfirmingEmail && (
                                <>
                                    <div className="mb-4 flex justify-center">
                                        <p className="text-lg font-bold mb-1">{email}</p>
                                        {!isGetShortsClicked && (
                                            <button
                                                onClick={handleEditEmail}
                                                className="text-gray-500 hover:text-gray-300 focus:outline-none mx-4"
                                            >
                                                Edit
                                            </button>
                                        )}
                                    </div>
                                </>
                            )}
                            {showEmailInput && !isConfirmingEmail && (
                                <div className="mb-4">
                                    {isEmailValid ? (
                                        <p className="text-base">
                                            {isEditingEmail
                                                ? 'Please enter your updated email:'
                                                : 'Would you like to set your email?'}
                                        </p>
                                    ) : (
                                        <p className="text-base text-red-500 mb-2">Please enter a valid email
                                            address.</p>
                                    )}
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        placeholder="Enter your email"
                                        className="w-full px-4 py-3 rounded-lg bg-gray-200 shadow-md focus:outline-none text-gray-800 placeholder-gray-500"
                                    />
                                    <div className="flex justify-center mt-2 space-x-4">
                                        <button
                                            onClick={handleEmailSubmit}
                                            style={{ backgroundColor: '#12DA02' }}
                                            className="px-4 py-2 rounded-lg text-black  hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-400 transform transition-all duration-300 ease-in-out hover:scale-105"
                                        >
                                            Set Email
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {isProcessing && (
                        <div className=" text-gray-200 rounded-lg p-6 mb-6">
                            <p className="text-3xl mb-6">
                                <span className="font-bold">Done from your side. </span>
                            </p>
                            <p className="text-2xl">You can safely close this page. </p>
                            <p className="text-lg">We'll send you an email to {email}, once your video is ready to
                                view.</p>
                        </div>
                    )}

                    {!showEmailInput && !successStatus && (
                        <button
                            onClick={handleGetShorts}
                            style={{ backgroundColor: '#12DA02' }}
                            className="w-full px-6 py-3 rounded-lg text-black  hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-400 transform transition-all duration-300 ease-in-out hover:scale-105"
                        >
                            {isLoading ? (
                                <div className="flex justify-center items-center">
                                    <FaSpinner className="animate-spin mr-2" />
                                    Processing...
                                </div>
                            ) : (
                                'Get Shorts'
                            )}
                        </button>
                    )}
                    {
                        successStatus &&
                        <a href="/" className="block mt-6">Go to Home</a>
                    }
                </div>
            </div>
        </div>
    );
};

export default WaitingPage;
