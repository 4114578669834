import React, {useEffect, useState} from 'react';
import Header from '../components/Header';
import ReactPlayer from 'react-player';
import axios from 'axios';
import UrlConstant from '../constants/UrlConstant';
import {v4 as uuidv4} from "uuid";
import {json, useNavigate} from "react-router";
import {FaMobileAlt, FaDesktop} from 'react-icons/fa';

function HomePage() {
    const [shorts, setShorts] = useState([]);
    const [selectedShort, setSelectedShort] = useState({});
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [savingTranscript, setSavingTranscript] = useState(false);
    const [progress, setProgress] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [newShortName, setNewShortName] = useState('');
    const [newShortFile, setNewShortFile] = useState(null);
    const [isVertical, setIsVertical] = useState(true);
    const [brandColor, setBrandColor] = useState(localStorage.getItem('brandColor') || '');
    const [brandColorErrorMsg, setBrandColorErrorMsg] = useState('');
    const [serverErrorMsg, setServerErrorMsg] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadResponse, setUploadResponse] = useState(null);

    const [startMark, setStartMark] = useState(null);
    const [endMark, setEndMark] = useState(null);
    const [editedSubtitles, setEditedSubtitles] = useState([]);

    const email = localStorage.getItem('email');
    const invitationCode = localStorage.getItem('invitationCode');
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
            navigate('/login');
            return;
        }

        document.addEventListener('keypress', event => handleKeyPress(event));

        axios.post(`${UrlConstant.HOST}/login`, {
            email: email,
            invitation_code: invitationCode,
        })
            .then(response => {
                localStorage.setItem("email", response.data.email);
                localStorage.setItem("invitationCode", response.data.invitation_code);
                localStorage.setItem("fullname", response.data.fullname);
                localStorage.setItem("invitedDate", response.data.invited_date);
                localStorage.setItem('subscriptionValidForDays', response.data.subscription_valid_for_days);
                localStorage.setItem('brandColor', response.data.brand_color);
            });

        axios
            .get(`${UrlConstant.HOST}/shorts_edit_request?email=${email}&invitation_code=${invitationCode}`)
            .then(response => {
                setShorts(response.data.shorts);
                setSelectedShort(response.data.shorts[0]);
                if (response.data.shorts[0].subs) {
                    setEditedSubtitles(response.data.shorts[0].subs);
                }
            })
            .catch(error => {
                console.error('Error fetching shorts:', error);
                setSelectedShort(null);
            });

        return () => {
            document.removeEventListener('keypress', event => handleKeyPress(event));
        };
    }, [email, invitationCode, startMark, endMark]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleFileChange = (e) => {
        setServerErrorMsg('');
        const file = e.target.files[0];
        if (!file) return;
        setNewShortFile(file);
    };

    const handleUpload = async () => {
        if (!newShortFile || !newShortName) return;
        const shorts_id = uuidv4().replace(/-/g, '');

        const formData = new FormData();
        formData.append('email', email);
        formData.append('invitation_code', invitationCode);
        formData.append('shorts_name', newShortName);
        formData.append('shorts_id', shorts_id);
        formData.append('file', newShortFile);
        formData.append('vertical', isVertical);

        try {
            setUploading(true);
            const response = await axios.post(`${UrlConstant.HOST}/upload_file_for_subs`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                },
            });

            let r = response.data
            let serverData = {
                subs: r.subs,
                shorts_id: r.shorts_id,
                shorts_name: r.shorts_name,
                vertical: r.vertical,
            }

            setUploadResponse(serverData);

            localStorage.setItem('uploadResponse', JSON.stringify(serverData))

            setEditedSubtitles(r.subs['subtitles']);
            setIsModalOpen(true);


            // Handle successful upload
            const newShort = {
                shorts_id: shorts_id,
                shorts_name: newShortName,
                status: 'PROCESSING', // Assuming newly uploaded short starts processing
            };

            shorts.unshift(newShort); // Add at the beginning of the list
            setSelectedShort(newShort);

            setEditMode(false);
            setNewShortName('');
            setNewShortFile(null);
            setProgress(0);

        } catch (error) {
            console.error('Error uploading file:', error);
            if (error.response && error.response.status === 401) {
                setServerErrorMsg(error.response.data.detail);
            } else {
                setServerErrorMsg('Error uploading file. Try again.');
            }
        } finally {
            setUploading(false);
        }
    };

    const handleEditNewShort = () => {
        setEditMode(true);
        setNewShortName('');
        setNewShortFile(null);
    };

    const handleBrandColorChange = async (e) => {
        setBrandColorErrorMsg('');
        const color = e.target.value;
        localStorage.setItem('brandColor', color);
        setBrandColor(color);
    };

    const handleColorSelectionDone = async (retryCount = 3) => {
        try {
            await axios.post(`${UrlConstant.HOST}/set_brand_color`, {
                email,
                invitation_code: invitationCode,
                new_color: brandColor,
            });
        } catch (error) {
            setBrandColorErrorMsg('Error while changing brand color. Try again.');
        }
    };

    const handleRemoveWord = (subtitleIndex, wordIndex) => {
        const updatedSubtitles = [...editedSubtitles];
        updatedSubtitles[subtitleIndex].words.splice(wordIndex, 1);
        setEditedSubtitles(updatedSubtitles);
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter" && startMark && endMark) {
            let updatedSubtitles = [...editedSubtitles];
            for (let i = startMark.index; i <= endMark.index; i++) {
                let start = i === startMark.index ? startMark.wordIndex : 0;
                let end = i === endMark.index ? endMark.wordIndex : editedSubtitles[i].words.length - 1;
                updatedSubtitles[i].words.splice(start, end - start + 1);
            }
            setEditedSubtitles(updatedSubtitles);
            setStartMark(null);
            setEndMark(null);
        }
    };

    const handleMarkClick = (index, wordIndex) => {
        // let markKey = `${index}_${wordIndex}`;
        // let previous = markVisibility[markKey] || false;

        // let updatedVisibility = { ...markVisibility, [markKey]: !previous };
        if (startMark && startMark.index === index && startMark.wordIndex === wordIndex) {
            setStartMark(null);

            if (endMark) {
                setStartMark(endMark);
                setEndMark(null);
            }
        } else if (endMark && endMark.index === index && endMark.wordIndex === wordIndex) {
            setEndMark(null);
        } else if (!startMark) {
            setStartMark({index, wordIndex});
            setEndMark(null);
        } else if (!endMark) {
            if (comesBefore({index, wordIndex}, startMark)) {
                setEndMark(startMark);
                setStartMark({index, wordIndex});
            } else {
                setEndMark({index, wordIndex});
            }

        } else {
            // setStartMark({...endMark});
            // setEndMark({ index, wordIndex });
        }

        // setMarkVisibility(updatedVisibility);
    };


    const isStartOrEnd = (index, wordIndex) => {
        return isStart(index, wordIndex) || isEnd(index, wordIndex);
    }

    const isStart = (index, wordIndex) => {
        return startMark && startMark.index === index && startMark.wordIndex === wordIndex;
    }

    const isEnd = (index, wordIndex) => {
        return endMark && endMark.index === index && endMark.wordIndex === wordIndex;
    }

    const comesBefore = (tuple1, tuple2) => {
        const {index1, wordIndex1} = tuple1;
        const {index2, wordIndex2} = tuple2;

        if (index1 < index2) {
            return true;
        } else if (index1 > index2) {
            return false;
        } else {
            return wordIndex1 < wordIndex2;
        }
    };

    const isBetweenStartAndEnd = (index, wordIndex) => {
        if (!startMark || !endMark) {
            return false;
        }

        const isBetween = (start, end, idx, wIdx) => {
            if (start.index < end.index) {
                if (idx < start.index || idx > end.index) return false;
                if (idx === start.index && wIdx < start.wordIndex) return false;
                if (idx === end.index && wIdx > end.wordIndex) return false;
            } else if (start.index > end.index) {
                if (idx < end.index || idx > start.index) return false;
                if (idx === end.index && wIdx < end.wordIndex) return false;
                if (idx === start.index && wIdx > start.wordIndex) return false;
            } else {
                if (idx !== start.index || wIdx < start.wordIndex || wIdx > end.wordIndex) return false;
            }
            return true;
        };

        // Check if the tuple is between startMark and endMark or endMark and startMark
        return isBetween(startMark, endMark, index, wordIndex) || isBetween(endMark, startMark, index, wordIndex);
    };


    const handleSaveTranscript = () => {
        setSavingTranscript(true);

        const formData = new FormData();
        formData.append('email', email);
        formData.append('invitation_code', invitationCode);
        formData.append('shorts_name', uploadResponse.shorts_name);
        formData.append('shorts_id', uploadResponse.shorts_id);
        formData.append('subtitles', JSON.stringify({'subtitles': editedSubtitles}));
        formData.append('vertical', isVertical);


        axios.post(`${UrlConstant.HOST}/shorts_edit_request_with_subtitles`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(response => {
                console.log('Transcript saved successfully:', response);
                setIsModalOpen(false);

                localStorage.removeItem('uploadResponse');
            })
            .catch(error => {
                console.error('Error saving transcript:', error);
            })
            .finally(() => setSavingTranscript(false));

    }

    const refreshTranscript = () => {
        if(localStorage.getItem('uploadResponse')){
            setEditedSubtitles(JSON.parse(localStorage.getItem('uploadResponse')).subs['subtitles'])
        }
    }

    return (
        <>
            <Header/>
            <div className="flex h-screen" style={{width: '70%', margin: '30px auto'}}>
                <div className={`text-white w-80 ${isSidebarOpen ? 'block' : 'hidden'} lg:block`}>
                    <div className="p-4">
                        {brandColorErrorMsg && <p className="mb-2 block text-red-500">{brandColorErrorMsg}</p>}

                        <div className="mb-8 flex items-center">
                            <span className="text-md font-bold mr-2">Brand Color</span>
                            <input
                                type="color"
                                value={brandColor}
                                onChange={handleBrandColorChange}
                                onBlur={handleColorSelectionDone}
                                className="ml-2"
                            />
                        </div>

                        {!editMode ? (
                            <div className="mb-8">
                                <button
                                    className="solid-button text-white px-4 py-2 rounded-lg focus:outline-none"
                                    onClick={handleEditNewShort}
                                >
                                    Edit New Short
                                </button>
                            </div>
                        ) : (
                            <div className="mb-8">
                                {serverErrorMsg && <p className="mb-2 block text-red-500">{serverErrorMsg}</p>}
                                <h2 className="text-md font-bold mb-2">Upload a Short to Edit</h2>
                                <input
                                    type="text"
                                    placeholder="Short's Name"
                                    value={newShortName}
                                    onChange={(e) => {
                                        setServerErrorMsg('');
                                        setNewShortName(e.target.value);
                                    }}
                                    className="input-style md:w-[400px]"
                                />
                                <input
                                    type="file"
                                    accept="video/*"
                                    onChange={handleFileChange}
                                    className="w-full md:w-[400px] cursor-pointer input-style border-b-2 border-gray-400 py-1 px-2 mb-2 mt-1 focus:outline-none focus:bg-white"
                                />
                                <div className="flex items-center mt-4">
                                    <label className="mr-4">Orientation:</label>
                                    <label className="flex items-center mr-4">
                                        <input
                                            type="radio"
                                            name="orientation"
                                            value="vertical"
                                            checked={isVertical}
                                            onChange={() => setIsVertical(true)}
                                            className="mr-2"
                                        />
                                        <FaMobileAlt size={20}/> <span>Vertical</span>
                                    </label>
                                    <label className="flex items-center">
                                        <input
                                            type="radio"
                                            name="orientation"
                                            value="horizontal"
                                            checked={!isVertical}
                                            onChange={() => setIsVertical(false)}
                                            className="mr-2"
                                        />
                                        <FaDesktop size={20} className="mr-1"/> <span>Horizontal</span>
                                    </label>
                                </div>
                                {uploading && (
                                    <div className="w-full bg-gray-200 rounded-full h-2.5 mt-12">
                                        <div className="bg-blue-500 h-2.5 rounded-full"
                                             style={{width: `${progress}%`}}></div>
                                    </div>
                                )}
                                {!uploading && (
                                    <div className="flex justify-end mt-8">
                                        <button
                                            className="solid-button mr-2"
                                            onClick={handleUpload}
                                            disabled={uploading || !newShortFile || !newShortName}
                                        >
                                            Upload
                                        </button>
                                        <button
                                            className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg focus:outline-none"
                                            onClick={() => setEditMode(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                )}
                                {uploading && <p className="text-sm text-gray-500 mt-2">Uploading ... {progress}%</p>}
                                {uploading && progress === 100 && <p className="text-sm text-gray-500 mt-2">Extracting subtitles ...</p>}
                            </div>
                        )}

                        <div>
                            {shorts.length > 0 && <h2 className="text-lg font-bold mb-4">Shorts</h2>}
                            <ul>
                                {shorts.map(short => (
                                    <li
                                        key={short.shorts_id}
                                        className={`p-3 mb-2 cursor-pointer ${selectedShort.shorts_id === short.shorts_id ? 'bg-gray-800 text-green-500' : 'hover:bg-gray-800'}`}
                                        onClick={() => setSelectedShort(short)}
                                    >
                                        {short.shorts_name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                {selectedShort && (
                    <div className="rounded-lg ml-72" style={{width: '50%'}}>
                        {selectedShort.status !== 'PROCESSING' ? (
                            <ReactPlayer
                                url={`${UrlConstant.HOST}/short_video_file?email=${email}&invitation_code=${invitationCode}&short_id=${selectedShort.shorts_id}&short_name=${selectedShort.shorts_name}`}
                                controls
                                width="90%"
                                height="70%"
                                className="rounded-lg"
                            />
                        ) : (
                            <div className="flex flex-col items-center justify-center h-full bg-gray-200 rounded-lg">
                                <p className="text-lg text-gray-600 mb-8 p-8">{selectedShort.shorts_name}</p>
                                <p className="text-lg text-gray-600">Currently being processed by server...</p>
                            </div>
                        )}
                    </div>
                )}

                <button
                    className="lg:hidden fixed bottom-4 right-4 p-2 bg-green-500 text-white rounded-full shadow-lg z-10"
                    onClick={toggleSidebar}
                >
                    {isSidebarOpen ? 'Close' : 'Open'} Shorts
                </button>
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-gray-100 text-gray-700 p-8 rounded-lg overflow-y-auto h-3/4 w-3/4">
                       <div className="flex justify-between">
                           <h2 className="text-xl font-bold mb-8">Remove retakes or fumbles if any. Select and hit Enter</h2>
                           <p className="text-sm cursor-pointer underline hover:text-green-800" onClick={refreshTranscript}>Refresh transcript to start fresh</p>
                       </div>

                        <div className="text-left">
                            {editedSubtitles.map((subtitle, index) => (
                                <div key={index}>
                                    <p>
                                        {subtitle.words.map((word, wordIndex) => (
                                            <React.Fragment key={`${index}_${wordIndex}`}>
                                    <span
                                        className={`mr-1 
                                        ${isStartOrEnd(index, wordIndex) ? 'border-2 border-red-900' : 'border-2 border-gray-100'} 
                                        hover:border-4  hover:border-red-400 hover:cursor-pointer
                                        
                                        `}
                                            onClick={() => handleMarkClick(index, wordIndex)}

                                    ></span>
                                                <button
                                                    className={`cursor-pointer
                                      ${isBetweenStartAndEnd(index, wordIndex) ? 'bg-red-200 rounded-md line-through' : ''}
                                      hover:text-red-600
                                      
                                    
                                    ` }
                                                    onClick={() => handleRemoveWord(index, wordIndex)}

                                                >
                                                    {word.word}&nbsp;&nbsp;
                                                </button>
                                            </React.Fragment>
                                        ))}
                                    </p>
                                    <p>&nbsp;</p>
                                </div>
                            ))}
                        </div>

                        <button
                            className="mt-4 solid-button"
                            onClick={handleSaveTranscript}
                        >
                            {savingTranscript ? 'Saving ... ' : 'Save' }
                        </button>
                        <button
                            className="mt-8 close-button ml-2"
                            onClick={() => {
                                setIsModalOpen(false);
                                localStorage.removeItem('uploadResponse');
                            }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default HomePage;
